import Img from 'gatsby-image';
import styled from 'styled-components';

import { theme } from '../utils/Theme';

export const Title = styled.h1`
  font-family: 'Recoleta-SemiBold';
  color: ${theme.colors.green};
  margin-bottom: 50px;
  font-size: 2.35rem;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
`;

export const Description = styled.div`
  font-family: 'Recoleta-Regular';
  margin: 0 auto;

  h3 {
    font-family: 'Recoleta-Medium';
    color: ${theme.colors.green};
    text-align: center;
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 0;

    h4 {
      font-family: 'Recoleta-Regular';
      font-style: italic;
      text-align: center;
    }

    p:last-child {
      margin-bottom: 20px;
    }
  }

  h5 {
    text-align: center;
    font-weight: 400;
    font-variant: small-caps;
  }

  strong {
    font-family: 'Recoleta-Regular';
  }

  ${theme.mediaQuerySizes.tablet} {
    width: 90%;
  }
`;

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;

  ${theme.mediaQuerySizes.tablet} {
    width: 100%;
  }
`;

export const StyledImage = styled(Img)`
  width: 400px;
  float: left;
  margin: 0 50px 50px 0;

  ${theme.mediaQuerySizes.tablet} {
    float: none;
    width: 80%;
    margin: 0 auto 30px auto;
  }

  ${theme.mediaQuerySizes.phoneLight} {
    width: 100%;
  }
`;

export const ThinTitle = styled.h1`
  font-family: 'Recoleta-Regular';
  ${theme.mediaQuerySizes.tablet} {
    font-size: 2rem;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    font-size: 1.7rem;
  }
`;

export const Image = styled(Img)`
  width: 400px;
  float: left;
  margin: 0 30px 30px 0;

  ${theme.mediaQuerySizes.tablet} {
    float: none;
    width: 80%;
    margin: 0 auto 30px auto;
  }

  ${theme.mediaQuerySizes.phoneLight} {
    width: 100%;
  }
`;

export const ThinDescription = styled.p``;
