import { AllMarkdownRemark } from '../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../interfaces/MarkdownFileNode';

export function findMarkdownFiles<T extends MarkdownFileNode>(
  allMarkdownRemark: AllMarkdownRemark<T> | undefined,
  markdownType: string,
  parentName?: string,
): T[] {
  return (
    allMarkdownRemark?.nodes.filter(
      (node: T) =>
        node.parent.relativeDirectory === markdownType && (parentName ? node.parent.name === parentName : true),
    ) || []
  );
}
