import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { ChildImageSharp } from '../../interfaces';
import { Title } from '../../styles/CommonStyledComponents';

const TitleSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 60px;
`;

export const StyledArrow = styled(Img)`
  width: 43px;
  display: inline-block;
  margin-bottom: -50px;
`;

interface HeadlineProps {
  arrowColor: keyof QueryResponse;
  title: string;
  className?: string;
}

interface QueryResponse {
  arrowBlue: ChildImageSharp;
  arrowGreen: ChildImageSharp;
  arrowPink: ChildImageSharp;
  arrowViolet: ChildImageSharp;
}

const query = graphql`
  query {
    arrowBlue: file(relativePath: { eq: "icons/section-arrow-blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 75, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arrowGreen: file(relativePath: { eq: "icons/section-arrow-green.png" }) {
      childImageSharp {
        fluid(maxWidth: 75, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arrowPink: file(relativePath: { eq: "icons/section-arrow-pink.png" }) {
      childImageSharp {
        fluid(maxWidth: 75, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arrowViolet: file(relativePath: { eq: "icons/section-arrow-violet.png" }) {
      childImageSharp {
        fluid(maxWidth: 75, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Headline = (props: HeadlineProps) => {
  return (
    <StaticQuery
      query={query}
      render={(data: QueryResponse) => (
        <TitleSection className={props.className ? props.className : ''}>
          <Title>
            {props.title}
            {arrow(data, props.arrowColor)}
          </Title>
        </TitleSection>
      )}
    />
  );
};

const arrow = (data: QueryResponse, arrowType?: keyof QueryResponse) => {
  return <StyledArrow fluid={data[arrowType ? arrowType : 'arrowGreen'].childImageSharp.fluid} />;
};

export default Headline;
