import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import GreenStripe from '../../components/GreenStripe';
import { PhotoGallery } from '../../components/PhotoGallery';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { LocationProps, PhotoGalleryChildImageSharp } from '../../interfaces';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { PageProps } from '../../interfaces/PageProps';
import { decodeUri } from '../../utils/DecodeUri';
import { findMarkdownFileByMarkdownFiles } from '../../utils/FindMarkdownFileByMarkdownFiles';
import { findMarkdownFiles } from '../../utils/FindMarkdownFiles';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';
import { theme } from '../../utils/Theme';

const Gallery = (props: PageProps) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/gallery/" } }) {
          nodes {
            frontmatter {
              title
              images {
                image {
                  childImageSharp {
                    bigImage: fluid(maxWidth: 2000, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              collection {
                collectionName
              }
            }
            parent {
              ... on File {
                name
                relativeDirectory
              }
            }
          }
        }
      }
    `,
  );

  const orderCollection: string[] =
    findMarkdownFiles<MarkdownFileNode>(
      data?.allMarkdownRemark,
      `settings/${getKindergartenByPathname(props.location.pathname)}`,
      'gallery',
    )[0]?.frontmatter?.collection?.map((c) => c.collectionName) || [];
  return (
    <>
      <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
      <GreenStripe />
      {orderCollection?.map((node: string, index: number) => (
        <Section
          id={
            findMarkdownFileByMarkdownFiles<MarkdownFileNode>(node, props.location, data?.allMarkdownRemark, 'gallery')
              ?.frontmatter?.title
          }
          backgroundColor={index % 2 == 0 ? theme.colors.white : theme.colors.creamy}
          key={index}
        >
          <PhotoGallery
            photos={findPhotos(data, props.location, orderCollection[index])!}
            title={node}
            withImages={false}
          ></PhotoGallery>
        </Section>
      ))}
    </>
  );
};

function findPhotos(
  data: AllMarkdownRemarkQueryResult<MarkdownFileNode>,
  location: LocationProps,
  orderCollectionName: string,
): PhotoGalleryChildImageSharp[] | undefined {
  return findMarkdownFileByMarkdownFiles<MarkdownFileNode>(
    orderCollectionName,
    location,
    data?.allMarkdownRemark,
    'gallery',
  )?.frontmatter?.images!.map((image) => {
    return image.image.childImageSharp;
  });
}

export default Gallery;
