import React from 'react';
import styled from 'styled-components';

import { theme } from '../utils/Theme';

interface GreenStripeProps {
  marginTop?: string;
}

const Stripe = styled.div<GreenStripeProps>`
  border-top: 5px ${theme.colors.green} solid;
  ${theme.mediaQuerySizes.smallLaptop} {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : 75)}px;
  }
`;

const GreenStripe = (props: GreenStripeProps) => {
  return <Stripe marginTop={props.marginTop} />;
};

export default GreenStripe;
