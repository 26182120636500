import React, { ReactFragment } from 'react';
import styled from 'styled-components';

import { encodeUri } from '../utils/EncodeUri';
import { removePolishSigns } from '../utils/RemovePolishSigns';
import { theme } from '../utils/Theme';

interface SectionProps {
  backgroundColor?: string;
  children?: ReactFragment;
  key?: number;
  id?: string;
  withoutPaddingBottom?: boolean;
}

const SectionContainer = styled.section<SectionProps>`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : theme.colors.white)};
  padding: ${(props) => (props.backgroundColor == 'transparent' ? '0px 20px 0px 20px' : '50px')};
  ${(props) => (props.withoutPaddingBottom ? 'padding-bottom: 0 !important' : '')};

  ${theme.mediaQuerySizes.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Section = ({ backgroundColor, children, id, withoutPaddingBottom }: SectionProps) => {
  return (
    <SectionContainer
      id={encodeUri(removePolishSigns(id || '') || '')}
      backgroundColor={backgroundColor}
      withoutPaddingBottom={withoutPaddingBottom}
    >
      {children}
    </SectionContainer>
  );
};
