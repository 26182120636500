import { LocationProps } from '../interfaces';
import { AllMarkdownRemark } from '../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../interfaces/MarkdownFileNode';
import { findMarkdownFiles } from './FindMarkdownFiles';
import { getKindergartenByPathname } from './GetKindergartenByPathname';

export const findMarkdownFileByMarkdownFiles = <T extends MarkdownFileNode>(
  node: string,
  location: LocationProps,
  allMarkdownRemark: AllMarkdownRemark<T> | undefined,
  galleryName: string,
): T | undefined => {
  return findMarkdownFiles<T>(
    allMarkdownRemark,
    `${galleryName}/${getKindergartenByPathname(location.pathname)}`,
  )?.find((mf) => mf?.frontmatter?.title === node);
};
